import React, {useEffect, useMemo, useState} from 'react';
import {Redirect, Router, useLocation} from "@reach/router";
import {ThemeProvider} from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import './App.css';
import {setMiniSidenav, useSoftUIController} from "./context";
import theme from './assets/theme';
import {Login} from "./pages/Login/Login";
import {Dashboard} from "./pages/Dashboard/Dashboard";
import Sidenav from "./layouts/comoponents/SideNav";
import brand from "./assets/images/logo-ct.png";
import {adminRoutes, routes} from "./routes/routes";
import {Error404} from "./pages/Error/404";
import {Offices} from "./pages/Offices/Offices";
import {useGlobalState} from "./context/GlobalStateProvider";
import {Logout} from "./pages/Logout/Logout";
import {getData} from "./api/api";
import {Rooms} from "./pages/Offices/Rooms";
import {OfficeAddEdit} from "./pages/Offices/OfficeAddEdit";
import {DialogControl} from "./components/Dialog/DialogControl";
import {SnackbarPopup} from "./components/Snackbar/SnackbarPopup";
import {RoomAddEdit} from "./pages/Offices/RoomAddEdit";
import {Users} from "./pages/Users/Users";
import {AllReservations} from "./pages/Reservations/AllReservations";
import {UserAddEdit} from "./pages/Users/UserAddEdit";
import {UserCredits} from "./pages/Users/UserCredits";
import {Registration} from "./pages/Registration/Registration";
import {RegistrationValidation} from "./pages/Registration/RegistrationValidation";
import {Credits} from "./pages/Credits/Credits";
import {CreditPurchaseWaiting} from "./pages/Credits/CreditPurchaseWaiting";
import {Packages} from "./pages/Packages/Pacakges";
import {PackageAddEdit} from "./pages/Packages/PackageAddEdit";
import {APDisplay} from "./pages/APDisplay/APDisplay";
import {ForgotPassword} from "./pages/ForgotPassword/ForgotPassword";
import {ForgotPasswordValidation} from "./pages/ForgotPassword/ForgotPasswordValidation";
import {ReservationsCalendar} from "./pages/Reservations/ReservationsCalendar";
import {AdminRoutes} from "./routes/AdminRoutes";
import {UserRoutes} from "./routes/UserRoutes";


function App() {
  const [controller, dispatch] = useSoftUIController();
  const {miniSidenav, direction, layout, openConfigurator, sidenavColor} = controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const [advanced, setAdvanced] = useState(false);
  const {pathname} = useLocation();
  const {state: globalState, setState: setGlobalState} = useGlobalState();


  const routeList = useMemo(() => advanced ? adminRoutes : routes, [advanced]);

  // Open sidenav when mouse enter on mini sidenav
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  useEffect(() => {
    if (globalState.attemptedTokenRead && globalState.userInfo && !globalState.configData) {
      getData("config").then((res) => {
        setGlobalState({configData: res.data.user, balance: res.data.balance});
        if (res.data.user?.admin) {
          setAdvanced(true);
        }
      })
        .catch((err) => {
          console.error(err);
        });
    }
  }, [globalState.attemptedTokenRead, globalState.userInfo]);

  if (!globalState.attemptedTokenRead) return null;
  if (globalState.attemptedTokenRead && globalState.userInfo && !globalState.configData) return null;
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline/>
      {layout === "dashboard" && (
        <>
          <Sidenav
            color={sidenavColor}
            brand={brand}
            brandName="Quiet Workplace"
            routes={routeList}
            onMouseEnter={handleOnMouseEnter}
            onMouseLeave={handleOnMouseLeave}
          />
          {/*<Configurator/>*/}
          {/*{configsButton}*/}
        </>
      )}
      {globalState.userInfo?.token ?
        <>
          {advanced ?
            <AdminRoutes/>
            :
            <UserRoutes/>
          }
        </>
        :
        <Router>
          <Login path={"/login"}/>
          <Registration path={"/register"}/>
          <RegistrationValidation path={"/register/validate/:code"}/>
          <ForgotPassword path={"/login/forgot"}/>
          <ForgotPasswordValidation path={"/login/forgot/:code"}/>
          <CreditPurchaseWaiting path={"/credits/purchase/:reference_id"}/>
          <APDisplay path={"/display/office/:office_id"}/>
          <Redirect noThrow={true} from={"*"} to={"/login"}/>
          {/*<Redirect from={"/logout"} to={"/login"}/>*/}
        </Router>
      }
      <SnackbarPopup/>
      <DialogControl/>
    </ThemeProvider>
  );
}

export default App;
