import {useEffect, useState} from "react";

// react-router-dom components
import {RouteComponentProps, useNavigate} from "@reach/router";

// @mui material components
import Card from "@mui/material/Card";

// Soft UI Dashboard PRO React components
// Authentication layout components
// Images
import {TSuiBox} from "../../components/TSuiBox";
import {TSuiTypography} from "../../components/TSuiTypography";
import DashboardLayout from "../../layouts/comoponents/DashboardLayout";
import {EventUserType, fromEventUserTypeDTO} from "../../interfaces/eventuser";
import {deleteData, getData, postData} from "../../api/api";
import FullCalendar from "@fullcalendar/react";
import interactionPlugin from "@fullcalendar/interaction";
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import {epochNow, parseDate} from "../../shared/dateutils";
import {ClientEvent} from "clientevent";
import {DialogContentType} from "../../components/Dialog/DialogControl";
import {ReservationEditor} from "../../modals/ReservationEditor";
import {useGlobalState} from "../../context/GlobalStateProvider";
import TSuiAlert from "../../components/TSuiAlert";
import apple_badge from "../../assets/images/apple_badge.svg";
import google_badge from "../../assets/images/google_badge.png";

interface Props extends RouteComponentProps {

}

export function Dashboard(props: Props) {
  const [events, setEvents] = useState<EventUserType[]>([]);
  const [editReservation, setEditReservation] = useState<string | undefined>();
  const [newReservation, setNewReservation] = useState<{
    start: number,
    end: number,
    office_id?: string,
    user_id: string
  } | undefined>();
  const {state: {configData, balance}} = useGlobalState();
  const navigate = useNavigate();

  const refreshList = async () => {
    const data = await getData("reservations").then((response) => (response.data || {
      events: []
    })).catch(() => ({
      events: []
    }));
    setEvents(prev => (data.events || []).map(fromEventUserTypeDTO));
  }

  const removeConfirm = (value: string) => {
    ClientEvent.emit("DIALOG", {
      type: "confirm",
      title: "Remove Reservation",
      message: "Are you sure you want to remove (cancel) this registration?",
      buttons: ["Yes", "No"],
      onClick: (value2) => {
        if (value2 === "Yes") {
          deleteData(`reservations/${value}`)
            .then(async (response) => {
              setEditReservation(undefined)
              ClientEvent.publish("SNACKBAR", "Removed Successfully");
              await refreshList();
            })
            .catch((err) => {
              ClientEvent.publish("SNACKBAR", err.message);
            });
        }
      }
    } as DialogContentType)
  }

  const saveReservation = (reservation: any, cb?: () => void) => {
    const {id, ...rest} = reservation;
    postData(`reservations/${id || "add"}`, {...rest})
      .then(async (response) => {
        setEditReservation(undefined)
        setNewReservation(undefined);
        ClientEvent.publish("SNACKBAR", `${id ? "Modified" : "Added"} Reservation`);
        await refreshList();
      })
      .catch((err) => {
        cb && cb();
        ClientEvent.publish("SNACKBAR", `Error: ${err.response.data}`);
      });
  }

  useEffect(() => {
    refreshList();
  }, [])

  return (
    <DashboardLayout>
      {balance !== undefined && balance > -1 && balance < 3 && (
        <TSuiAlert color="info" onClick={() => navigate('/credits')}>
          <>
            {balance === 0 && (<>You have 0 credits! Click here to add more.</>)}
            {balance > 0 && (<>You are running low on credits, please purchase credits soon.</>)}
          </>
        </TSuiAlert>
      )}
      <Card>
        <TSuiBox p={3} mb={0} textAlign="center" paddingBottom={0}>
          <TSuiTypography variant="h5" fontWeight="medium">
            My Reservations
          </TSuiTypography>
        </TSuiBox>
        <TSuiBox display={"block"} textAlign={"center"}>
          <TSuiTypography pl={3}>Download our mobile app to manage</TSuiTypography>
          <div>
            <a href={"https://apps.apple.com/us/app/quietworks/id1609848617"}>
              <img src={apple_badge} height={40} alt={"Download App from Apple App Store"}/>
            </a>
            <a href={"https://play.google.com/store/apps/details?id=com.ccsrvs.quietworks"}>
              <img src={google_badge} height={40} alt={"Download App from Google Play Store"}/>
            </a>
          </div>
        </TSuiBox>
        <TSuiBox p={3} paddingTop={0}>
          <FullCalendar
            plugins={[interactionPlugin, dayGridPlugin, timeGridPlugin, listPlugin]}
            height={"auto"}
            initialView="listWeek"
            // schedulerLicenseKey={"CC-Attribution-NonCommercial-NoDerivatives"}
            weekends={true}
            slotDuration={'00:15:00'}
            // editable={true}
            timeZone={"UTC"}
            events={events}
            // nowIndicator={true}
            // scrollTimeReset={false}
            slotMinTime={"06:00:00"}
            slotMaxTime={"20:00:00"}
            snapDuration={"00:30:00"}
            // dayMinWidth={1}
            allDaySlot={false}
            eventMinWidth={1}
            slotMinWidth={5}
            // selectable={true}
            selectMirror={true}
            // scrollTime={epochNow().format("HH:MM:SS")}
            eventChange={(event) => {
              const newReservation = {
                id: event.event.id,
                start: parseDate(event.event.startStr).unix(),
                end: parseDate(event.event.endStr).unix(),
                office_id: event.event.extendedProps.resource
              }
              saveReservation(newReservation, () => {
                event.revert();
              });
            }}
            // select={(dated) => {
            //   if (dated.view.type === "timeGridWeek") {
            //     setNewReservation({
            //       start: (parseDate(dated.startStr).unix()),
            //       end: (parseDate(dated.endStr).unix()),
            //       office_id: null,
            //       user_id: configData.id
            //     });
            //   }
            // }}
            // eventClick={(dated) => {
            //   // console.log("eventClick", dated)
            //   if (false === dated.event['startEditable']) {
            //     return;
            //   }
            //   setEditReservation(dated.event.id);
            // }}
            now={() => epochNow().toDate()}
            customButtons={{
              myCustomButton: {
                text: 'refresh',
                click: () => {
                  refreshList();
                }
              }
            }}
            headerToolbar={{
              left: 'prev,next today',
              center: 'title',
              right: 'myCustomButton'
            }}
          />
        </TSuiBox>
      </Card>
      {/*<ReservationEditor reservationId={editReservation}*/}
      {/*                   newReservationData={newReservation}*/}
      {/*                   onRemove={() => {*/}
      {/*                     removeConfirm(editReservation)*/}
      {/*                   }}*/}
      {/*                   onClose={() => {*/}
      {/*                     setEditReservation(undefined);*/}
      {/*                     setNewReservation(undefined)*/}
      {/*                   }}*/}
      {/*                   onSave={(reservation) => {*/}
      {/*                     // console.log(reservation)*/}
      {/*                     saveReservation(reservation)*/}
      {/*                   }}*/}
      {/*/>*/}
    </DashboardLayout>
  );
}
