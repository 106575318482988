import {useState} from "react";

// react-router-dom components
import {Link, RouteComponentProps} from "@reach/router";

// @mui material components
import Card from "@mui/material/Card";

// Soft UI Dashboard PRO React components
// Authentication layout components
import BasicLayout from "../../layouts/comoponents/BasicLayout";

// Images
import curved9 from "../../assets/images/first-slide.jpg";
import {TSuiBox} from "../../components/TSuiBox";
import {TSuiTypography} from "../../components/TSuiTypography";
import {TSuiButton} from "../../components/TSuiButton";
import {TSuiInput} from "../../components/TSuiInput";
import {useGlobalState} from "../../context/GlobalStateProvider";
import {getData, postData} from "../../api/api";
import {ClientEvent} from "clientevent";
import Checkbox from "@mui/material/Checkbox/Checkbox";
import {ModalMaker} from "../../components/ModalMaker/ModalMaker";
import {IPackage} from "../../interfaces/packages";
import {PackageCards} from "../../components/PackageCards/PackageCards";
import * as regex from "../../constants/regex";

interface Props extends RouteComponentProps {

}

interface RegistrationForm {
  email: string;
  password: string;
  first_name: string;
  last_name: string;
  address: string;
  city: string;
  state: string;
  zip: string;
  promo_code: string;
  terms: boolean;
};
let defaultReg: RegistrationForm = {
  email: "",
  password: "",
  first_name: "",
  last_name: "",
  address: "",
  city: "",
  state: "",
  zip: "",
  promo_code: "",
  terms: false
};

export function Registration(props: Props) {
  // const [, dispatch] = useSoftUIController();
  // const [rememberMe, setRememberMe] = useState(false);
  const [userVals, setUserVals] = useState<RegistrationForm>({...defaultReg});
  const {setState} = useGlobalState();
  const [showTerms, setShowTerms] = useState(false);
  const [viewedTerms, setViewedTerms] = useState(false);
  const [showSignupResult, setShowSignupResult] = useState(false);
  const [packageData, setPackageData] = useState<IPackage[]>([]);
  const [userId, setUserId] = useState<string>("");

  const userValHandler = (key: keyof RegistrationForm, val: any) => {
    setUserVals(prev => ({...prev, [key]: val}));
  };

  // const handleSetRememberMe = () => setRememberMe(!rememberMe);
  let grabPackagesData = (user_id: string) => {
    setUserId(user_id);
    getData("packages").then(async (result) => {
      if (result.status === 200) {
        console.log(result.data && result.data.packages);
        setPackageData(result.data && result.data.packages || []);
        // setState({userInfo: {username: "", token: result.data.token}});
      } else {
        ClientEvent.publish("SNACKBAR", `Error: ${result.statusText}`);
        console.log(result.statusText)
      }
    }).catch((err) => {
      ClientEvent.publish("SNACKBAR", `Error: ${err.response.statusText}`);
      console.log(err.response.statusText)
    }).finally(() => {
      setShowSignupResult(true);
    });

  }

  let onSubmit = () => {
    // return grabPackagesData("1234"); //TODO: remove this line
    if (userVals.email === "") {
      return ClientEvent.publish("SNACKBAR", `Error: Email is required`);
    }
    if (userVals.password === "") {
      return ClientEvent.publish("SNACKBAR", `Error: Password is required`);
    }
    if (!regex.password.test(userVals.password)) {
      ClientEvent.publish("SNACKBAR", `Error: A valid password is required`);
    }
    if (userVals.first_name === "") {
      return ClientEvent.publish("SNACKBAR", `Error: First Name is required`);
    }
    if (userVals.last_name === "") {
      return ClientEvent.publish("SNACKBAR", `Error: Last Name is required`);
    }
    if (userVals.zip === "") {
      return ClientEvent.publish("SNACKBAR", `Error: Zip Code is required`);
    }
    if (!userVals.terms) {
      return ClientEvent.publish("SNACKBAR", `Error: You must agree to the terms`);
    }
    const {terms, ...restVals} = userVals;
    postData("register", restVals).then(async (result) => {
      if (result.status === 200) {
        grabPackagesData(result.data.id || "");
        // setState({userInfo: {username: "", token: result.data.token}});
      } else {
        ClientEvent.publish("SNACKBAR", `Error: ${result.statusText}`);
        console.log(result.statusText)
      }
    }).catch((err) => {
      console.log(err)
      ClientEvent.publish("SNACKBAR", `Error: ${err.response.statusText}`);
    })
  };

  const onCheckboxChange = () => {
    if (!viewedTerms) {
      setShowTerms(true);
    }
  }

  return (
    <BasicLayout
      title="Welcome!"
      description="Register for an account today!"
      image={curved9}
      gridOverrides={{lg: 8, xl: 6, md: 8, sm: 11, xs: 12}}
    >
      {!showSignupResult ?
        <Card>
          <TSuiBox p={3} mb={1} textAlign="center">
            <TSuiTypography variant="h5" fontWeight="medium">
              Register
            </TSuiTypography>
          </TSuiBox>
          <TSuiBox p={3}>
            <TSuiBox component="form" role="form" onSubmit={(evt) => {
              evt.preventDefault();
            }}>

              <TSuiBox mb={2} display={"flex"}>
                <TSuiInput type="text"
                           placeholder="First Name"
                           value={userVals.first_name}
                           onChange={(evt) => userValHandler("first_name", evt.target.value)}/>
                <TSuiInput type="text"
                           placeholder="Last Name"
                           value={userVals.last_name}
                           onChange={(evt) => userValHandler("last_name", evt.target.value)}/>
              </TSuiBox>
              <TSuiBox mb={2}>
                <TSuiInput type="text"
                           placeholder="Address"
                           value={userVals.address}
                           onChange={(evt) => userValHandler("address", evt.target.value)}/>
              </TSuiBox>
              <TSuiBox mb={2} display={"flex"}>
                <TSuiInput type="text"
                           placeholder="City"
                           sx={{width: "50%"}}
                           value={userVals.city}
                           onChange={(evt) => userValHandler("city", evt.target.value)}/>
                <TSuiInput type="text"
                           placeholder="State"
                           sx={{width: "25%"}}
                           value={userVals.state}
                           onChange={(evt) => userValHandler("state", evt.target.value)}/>
                <TSuiInput type="text"
                           placeholder="Zip"
                           value={userVals.zip}
                           sx={{width: "25%"}}
                           onChange={(evt) => userValHandler("zip", evt.target.value)}/>
              </TSuiBox>

              <TSuiBox mb={2}>
                <TSuiInput type="email"
                           placeholder="Email"
                           value={userVals.email}
                           error={!regex.email.test(userVals.email)}
                           success={regex.email.test(userVals.email)}
                           onChange={(evt) => userValHandler("email", evt.target.value)}/>
              </TSuiBox>
              <TSuiBox mb={2}>
                <TSuiInput type="password"
                           placeholder="Password"
                           value={userVals.password}
                           autoComplete={"new-password"}
                           error={!regex.password.test(userVals.password)}
                           success={regex.password.test(userVals.password)}
                           onKeyUp={(evt) => {
                             if (evt.key === "Enter") {
                               evt.preventDefault();
                               evt.stopPropagation();
                               onSubmit();
                             }
                           }}
                           onChange={(evt) => userValHandler("password", evt.target.value)}/>
                {!regex.password.test(userVals.password) && (
                  <TSuiBox display={"flex"} flexDirection={"column"}>
                    <TSuiTypography variant="caption" color="text" fontWeight="regular">
                      Requirements:
                    </TSuiTypography>
                    <TSuiTypography variant={"caption"} color={"text"} fontWeight={"regular"}>
                      &nbsp;&nbsp;&nbsp;&#x2022;&nbsp;at least 6 characters long
                    </TSuiTypography>
                    <TSuiTypography variant={"caption"} color={"text"} fontWeight={"regular"}>
                      &nbsp;&nbsp;&nbsp;&#x2022;&nbsp;contain at least 1 uppercase letter
                    </TSuiTypography>
                    <TSuiTypography variant={"caption"} color={"text"} fontWeight={"regular"}>
                      &nbsp;&nbsp;&nbsp;&#x2022;&nbsp;contain at least 1 lowercase letter
                    </TSuiTypography>
                    <TSuiTypography variant={"caption"} color={"text"} fontWeight={"regular"}>
                      &nbsp;&nbsp;&nbsp;&#x2022;&nbsp;contain at least 1 number
                    </TSuiTypography>
                  </TSuiBox>
                )}
              </TSuiBox>
              {/*<TSuiBox pt={2} mb={2}>*/}
              {/*  <TSuiInput type="text"*/}
              {/*             placeholder="Promo Code"*/}
              {/*             value={userVals.promo_code}*/}
              {/*             onChange={(evt) => userValHandler("promo_code", evt.target.value)}/>*/}
              {/*</TSuiBox>*/}
              {/*<TSuiBox display="flex" alignItems="center">*/}
              {/*  <Switch checked={true} onChange={() => undefined}/>*/}
              {/*  <TSuiTypography*/}
              {/*    variant="button"*/}
              {/*    fontWeight="regular"*/}
              {/*    onClick={() => undefined}*/}
              {/*    sx={{cursor: "pointer", userSelect: "none"}}*/}
              {/*  >*/}
              {/*    &nbsp;&nbsp;Remember me*/}
              {/*  </TSuiTypography>*/}
              {/*</TSuiBox>*/}
              <TSuiBox display="flex" alignItems="center">
                <Checkbox checked={userVals.terms} onChange={() => onCheckboxChange()}/>
                <TSuiTypography
                  variant="button"
                  fontWeight="regular"
                  onClick={() => onCheckboxChange()}
                  sx={{cursor: "pointer", userSelect: "none"}}
                >
                  &nbsp;&nbsp;I agree the&nbsp;
                </TSuiTypography>
                <TSuiTypography onClick={() => setShowTerms(true)}
                                variant="button"
                                fontWeight="bold"
                                sx={{cursor: "pointer", userSelect: "none"}}
                                textGradient>
                  Terms and Conditions
                </TSuiTypography>
              </TSuiBox>
              <TSuiBox mt={4} mb={1}>
                <TSuiButton variant="gradient" color="info" fullWidth onClick={onSubmit}>
                  Register
                </TSuiButton>
              </TSuiBox>
              <TSuiTypography variant="button" color="text" fontWeight="regular">
                <>Already have an account?&nbsp;</>
                <TSuiTypography
                  component={Link}
                  to="/login"
                  variant="button"
                  color="dark"
                  fontWeight="bold"
                  textGradient
                >
                  Sign in
                </TSuiTypography>
              </TSuiTypography>
            </TSuiBox>
          </TSuiBox>
        </Card>
        :
        <>
          <Card>
            <TSuiBox p={3} mb={1} textAlign="center">
              <TSuiTypography variant="h5" fontWeight="medium">
                Register
              </TSuiTypography>
            </TSuiBox>
            <TSuiBox p={3}>
              <div>Initial Registration Successful! Please check your email for a verification link.
                {packageData.length > 0 && <>
                  &nbsp;While you wait, purchase one of our packages:
                </>}
              </div>
              <PackageCards packageData={packageData} userId={userId}/>
              <TSuiTypography variant="button" color="text" fontWeight="regular">
                <TSuiBox p={3}>
                  (If you don't see our verification email, check your Junk Mail folder)
                </TSuiBox>


                <>Go to &nbsp;</>
                <TSuiTypography
                  component={Link}
                  to="/login"
                  variant="button"
                  color="dark"
                  fontWeight="bold"
                  textGradient
                >
                  Sign in
                </TSuiTypography>
                <>&nbsp; form</>
              </TSuiTypography>
            </TSuiBox>
          </Card>
        </>
      }
      {showTerms && (
        <ModalMaker title={"Terms and Conditions"}
                    open={true}
                    actions={<>
                      <TSuiButton variant="gradient" color="info" fullWidth
                                  onClick={() => {
                                    userValHandler("terms", true);
                                    setShowTerms(false)
                                  }}>
                        I Accept
                      </TSuiButton>
                      <TSuiButton variant="gradient" color="dark" fullWidth onClick={() => {
                        userValHandler("terms", false);
                        setShowTerms(false)
                      }}>
                        I Decline
                      </TSuiButton>
                    </>}>
          <iframe width={"100%"} src={"https://www.iubenda.com/terms-and-conditions/44326513"}
                  height={400}></iframe>
        </ModalMaker>
      )}
    </BasicLayout>
  );
}
