import Settings from "@mui/icons-material/Settings";
import Shop2Outlined from "@mui/icons-material/Shop2Outlined";
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import CalendarMonth from '@mui/icons-material/CalendarMonth';
import {Offices} from "../pages/Offices/Offices";
import {MarginOutlined} from "@mui/icons-material";

export type IRoute = IRouteCollapse | IRouteNoCollapse | IRouteTitle | IRouteDivider | IRouteRoute;

export interface IRouteCollapse {
  type: "collapse";
  name: string;
  key: string;
  icon: JSX.Element;
  collapse: (IRouteCollapseRoute)[];
}

export interface IRouteRoute extends Omit<IRouteCollapseRoute, "collapse"> {
  type: "route";
}

export interface IRouteCollapseRoute {
  name: string;
  key: string;
  icon?: JSX.Element;
  route?: string;
  component?: JSX.Element;
  collapse?: [];
}

export interface IRouteTitle {
  type: "title";
  title: string;
  key: string;
}

export interface IRouteDivider {
  type: "divider";
  key: string;
}


export const routes: IRoute[] = [
  {type: "route", name: "Home", key: "home", route: "/", icon: <MarginOutlined/>},
  {type: "route", name: "Credits", key: "credits", route: "/credits", icon: <Shop2Outlined/>},
  {type: "divider", key: "div1"},
  {type: "route", name: "Logout", key: "logout", route: "/logout", icon: <PowerSettingsNewIcon/>}

];

export const adminRoutes: IRoute[] = [
  {type: "route", name: "Home", key: "home", route: "/", icon: <MarginOutlined/>},
  {type: "route", name: "Credits", key: "credits", route: "/credits", icon: <Shop2Outlined/>},
  {type: "divider", key: "div1"},
  {type: "title", title: "Admin Section", key: "title1"},
  {type: "route", name: "Reservations Today", key: "reservations-all", route: "/reservations-all", icon: <CalendarMonth/>},
  {type: "route", name: "Reservations Calendar", key: "reservations-calendar", route: "/reservations-calendar", icon: <CalendarMonth/>},
  {
    type: "collapse", name: "Settings", key: "settings", icon: <Settings/>, collapse: [
      {name: "Offices", key: "office", route: "/settings/office", icon: <Settings/>},
      {name: "Users", key: "users", route: "/settings/users", icon: <Settings/>},
      {name: "Packages", key: "packages", route: "/settings/packages", icon: <Settings/>}
    ],
  },
  {type: "route", name: "Logout", key: "logout", route: "/logout", icon: <PowerSettingsNewIcon/>}

];

interface IRouteNoCollapse extends IRouteCollapse {
  href: string;
  noCollapse: true;
}