import {useEffect, useState} from "react";

// react-router-dom components
import {Link, RouteComponentProps, useNavigate} from "@reach/router";

// @mui material components
import Card from "@mui/material/Card";

// Soft UI Dashboard PRO React components
// Authentication layout components
import BasicLayout from "../../layouts/comoponents/BasicLayout";

// Images
import curved9 from "../../assets/images/first-slide.jpg";
import {TSuiBox} from "../../components/TSuiBox";
import {TSuiTypography} from "../../components/TSuiTypography";
import {TSuiButton} from "../../components/TSuiButton";
import {TSuiInput} from "../../components/TSuiInput";
import {getData, postData} from "../../api/api";
import {ClientEvent} from "clientevent";
import * as regex from "../../constants/regex";

interface Props extends RouteComponentProps {
  code?: string;
}

interface RegistrationForm {
  code: string;
  password?: string;
  password2?: string;
}

let defaultReg: RegistrationForm = {
  code: "",
  password: "",
  password2: "",
};

export function ForgotPasswordValidation(props: Props) {
  const navigate = useNavigate();
  const [userVals, setUserVals] = useState<RegistrationForm>({...defaultReg, code: props.code || ""});
  const [newPass, setNewPass] = useState(false);

  const userValHandler = (key: keyof RegistrationForm, val: any) => {
    setUserVals(prev => ({...prev, [key]: val}));
  };

  // const handleSetRememberMe = () => setRememberMe(!rememberMe);

  let onSubmit = () => {
    if (userVals.code === "") {
      return ClientEvent.publish("SNACKBAR", `Error: Code is Required`);
    }
    getData(`forgot/${userVals.code}`, {}).then(async (result) => {
      if (result.status === 200) {
        setNewPass(true);
        // setState({userInfo: {username: "", token: result.data.token}});
        // navigate(`/`);
      } else {
        ClientEvent.publish("SNACKBAR", `Error: ${result.statusText}`);
        console.log(result.statusText)
      }
    }).catch((err) => {
      console.log(err)
      ClientEvent.publish("SNACKBAR", `Error: ${err.response && err.response.data || err.response.statusText}`);
    })
  };

  let onSubmitPass = () => {
    if (userVals.code === "") {
      return ClientEvent.publish("SNACKBAR", `Error: Code is Required`);
    }
    if (userVals.password === "") {
      return ClientEvent.publish("SNACKBAR", `Error: Password is required`);
    }
    if (!regex.password.test(userVals.password)) {
      ClientEvent.publish("SNACKBAR", `Error: A valid password is required`);
    }
    if (userVals.password !== userVals.password2) {
      return ClientEvent.publish("SNACKBAR", `Error: Passwords do not match`);
    }
    const {code, password} = userVals;
    postData(`forgot/${userVals.code}`, {code, password}).then(async (result) => {
      if (result.status === 200) {
        // setState({userInfo: {username: "", token: result.data.token}});
        ClientEvent.publish("SNACKBAR", `Login using your new password`);
        navigate(`/`);
      } else {
        ClientEvent.publish("SNACKBAR", `Error: ${result.statusText}`);
        console.log(result.statusText)
      }
    }).catch((err) => {
      console.log(err)
      ClientEvent.publish("SNACKBAR", `Error: ${err.response.statusText}`);
    })
  };


  useEffect(() => {
    if (props.code) {
      onSubmit();
    }
  }, [props.code]);

  return (
    <BasicLayout
      title="Welcome!"
      description=""
      image={curved9}
    >
      {!newPass ? (
        <Card>
          <TSuiBox p={3} mb={1} textAlign="center">
            <TSuiTypography variant="h5" fontWeight="medium">
              Forgot Password Validation
            </TSuiTypography>
          </TSuiBox>
          <TSuiBox p={3}>
            <TSuiBox component="form" role="form" onSubmit={(evt) => {
              evt.preventDefault();
              onSubmit();
            }}>
              <TSuiBox mb={2}>
                <TSuiInput type="code"
                           placeholder="Code"
                           value={userVals.code}
                           onChange={(evt) => userValHandler("code", evt.target.value)}/>
              </TSuiBox>
              <TSuiBox mt={4} mb={1}>
                <TSuiButton variant="gradient" color="info" fullWidth onClick={onSubmit}>
                  Validate
                </TSuiButton>
              </TSuiBox>
              <TSuiTypography variant="button" color="text" fontWeight="regular">
                <>Remember your credentials?&nbsp;</>
                <TSuiTypography
                  component={Link}
                  to="/login"
                  variant="button"
                  color="dark"
                  fontWeight="bold"
                  textGradient
                >
                  Sign in
                </TSuiTypography>
              </TSuiTypography>
            </TSuiBox>
          </TSuiBox>
        </Card>
      ) : (
        <Card>
          <TSuiBox p={3} mb={1} textAlign="center">
            <TSuiTypography variant="h5" fontWeight="medium">
              Enter new Password
            </TSuiTypography>
          </TSuiBox>
          <TSuiBox p={3}>
            <TSuiBox component="form" role="form" onSubmit={(evt) => {
              evt.preventDefault();
              onSubmitPass();
            }}>
              <TSuiBox mb={2}>
                <TSuiInput type="password"
                           placeholder="Password"
                           value={userVals.password}
                           error={!regex.password.test(userVals.password)}
                           success={regex.password.test(userVals.password)}
                           autoComplete={"new-password"}
                           onChange={(evt) => userValHandler("password", evt.target.value)}/>
              </TSuiBox>
              <TSuiBox mb={2}>
                <TSuiInput type="password"
                           placeholder="Password Again"
                           autoComplete={"new-password"}
                           value={userVals.password2}
                           error={!regex.password.test(userVals.password2) || userVals.password !== userVals.password2}
                           success={regex.password.test(userVals.password2) && userVals.password === userVals.password2}
                           onChange={(evt) => userValHandler("password2", evt.target.value)}/>
                {(!regex.password.test(userVals.password) || !regex.password.test(userVals.password2)) && (
                  <TSuiBox display={"flex"} flexDirection={"column"}>
                    <TSuiTypography variant="caption" color="text" fontWeight="regular">
                      Requirements:
                    </TSuiTypography>
                    <TSuiTypography variant={"caption"} color={"text"} fontWeight={"regular"}>
                      &nbsp;&nbsp;&nbsp;&#x2022;&nbsp;at least 6 characters long
                    </TSuiTypography>
                    <TSuiTypography variant={"caption"} color={"text"} fontWeight={"regular"}>
                      &nbsp;&nbsp;&nbsp;&#x2022;&nbsp;contain at least 1 uppercase letter
                    </TSuiTypography>
                    <TSuiTypography variant={"caption"} color={"text"} fontWeight={"regular"}>
                      &nbsp;&nbsp;&nbsp;&#x2022;&nbsp;contain at least 1 lowercase letter
                    </TSuiTypography>
                    <TSuiTypography variant={"caption"} color={"text"} fontWeight={"regular"}>
                      &nbsp;&nbsp;&nbsp;&#x2022;&nbsp;contain at least 1 number
                    </TSuiTypography>
                  </TSuiBox>
                )}
              </TSuiBox>
              <TSuiBox mt={4} mb={1}>
                <TSuiButton variant="gradient" color="info" fullWidth onClick={onSubmitPass}>
                  Change Password
                </TSuiButton>
              </TSuiBox>
              <TSuiTypography variant="button" color="text" fontWeight="regular">
                <>Remember your credentials?&nbsp;</>
                <TSuiTypography
                  component={Link}
                  to="/login"
                  variant="button"
                  color="dark"
                  fontWeight="bold"
                  textGradient
                >
                  Sign in
                </TSuiTypography>
              </TSuiTypography>
            </TSuiBox>
          </TSuiBox>
        </Card>
      )}
    </BasicLayout>
  );
}
