import {useEffect, useRef, useState} from "react";

// react-router-dom components
import {RouteComponentProps} from "@reach/router";

// @mui material components
// Soft UI Dashboard PRO React components
// Authentication layout components
// Images
import {TSuiBox} from "../../components/TSuiBox";
import {TSuiTypography} from "../../components/TSuiTypography";
import {getData, postData} from "../../api/api";
import APDisplayLayout from "../../layouts/comoponents/APDisplayLayout";
import officeMap from "./QWPMap.jpg"
import {socketConnector} from "../../shared/SocketConnector";
import {ClientEvent} from "clientevent";
import {parseDate} from "../../shared/dateutils";

interface Props extends RouteComponentProps {
  office_id?: string;
}

interface CheckinList {
  name: string,
  room: string,
}

interface UpcomingList {
  name: string,
  time: number,
}

interface PageData {
  checkins: CheckinList[],
  upcoming: string[],
  upcomingAppts: UpcomingList[],
}

export function APDisplay(props: Props) {
  const timerRef = useRef<any>();
  const timerVal = useRef<number>(0);
  const [pageData, setPageData] = useState<PageData>({checkins: [], upcoming: [], upcomingAppts: []});
  const checkinRef = useRef<any>();
  const refreshList = async () => {
    timerVal.current = 0;
    await getData<PageData>(`apdisplay/${props.office_id}/${(new Date()).getTimezoneOffset()}`)
      .then((response) => {
        if (response.status === 200) {
          setPageData(response.data);
        }
      })
      .catch((err) => {
        ClientEvent.publish("SNACKBAR", `Error: ${err.message}}`);
      });
  }

  const handleCheckIn = async () => {
    const code = checkinRef.current.value;
    if (code.length > 0) {
      await postData<any>(`apdisplay/${props.office_id}/checkin/${(new Date()).getTimezoneOffset()}`, {prox_card: code})
        .then((response) => {
          if (response.status === 200) {
            ClientEvent.publish("SNACKBAR", `Welcome ${response.data.name}`);
          }
        })
        .catch((err) => {
          // console.log(err)
          ClientEvent.publish("SNACKBAR", `Error: ${err.response && err.response.data || err.message}`);
        });
    } else {
      ClientEvent.publish("SNACKBAR", `Error code scan error`);
    }
    checkinRef.current.value = "";
  }

  const handleKeyPress = (e: any) => {
    if (e.key === "Enter") {
      handleCheckIn();
    }
  }


  useEffect(() => {
    const displayListener = (payload: string) => {
      if (payload === "refresh") refreshList();
      if (payload === "reload") window.location.reload();
    }
    const autoFocus = () => {
      if (checkinRef.current) {
        checkinRef.current.focus();
      }
    }
    if (props.office_id === "test") {
      setPageData({
        checkins: [
          {name: "J. McCormack", room: "Q1"},
          {name: "M. McKinney", room: "Q4"},
          {name: "E. Fudd", room: "Q6"},
        ],
        upcoming: ["J. Wentz", "M. Sigismund"],
        upcomingAppts: [
          {name: "J. Wentz", time: 1634230800},
          {name: "M. Sigismund", time: 1634231800},
        ]
      })
    } else {
      refreshList();
      socketConnector.subscribe(`display:${props.office_id}`, displayListener);
      timerRef.current = setInterval(() => {
        timerVal.current++;
        if (timerVal.current >= 5) {
          refreshList();
        }
      }, 1000 * 60);
    }
    if (checkinRef.current) {
      checkinRef.current.onblur = autoFocus;
      autoFocus();
    }
    return () => {
      if (props.office_id !== "test") {
        socketConnector.unsubscribeAll(`display:${props.office_id}`);
        clearInterval(timerRef.current);
      }
      if (checkinRef.current) {
        checkinRef.current.onblur = undefined
      }
    }
  }, [])

  return (
    <APDisplayLayout lg={6} xl={6} md={12} sm={12} xs={12} bgImage={officeMap} bgColor={"#979899"}>
      <TSuiBox sx={{position: "absolute", opacity: 0}}>
        <input type={"text"} ref={checkinRef} onKeyDown={handleKeyPress}/>
      </TSuiBox>
      {/*{balance !== undefined && balance > -1 && balance < 3 && (*/}
      {/*  <TSuiAlert color="info" onClick={() => navigate('/credits')}>*/}
      {/*    <>*/}
      {/*      {balance === 0 && (<>You have 0 credits! Click here to add more.</>)}*/}
      {/*      {balance > 0 && (<>You are running low on credits, please purchase credits soon.</>)}*/}
      {/*    </>*/}
      {/*  </TSuiAlert>*/}
      {/*)}*/}
      {/*<Card sx={{backgroundColor:"#979899"}} raised={false}>*/}
      <TSuiBox height={"95vh"}>
        <TSuiBox p={0} mb={0} textAlign="center" paddingBottom={0}>
          <TSuiTypography variant="h1" fontWeight={"bold"} fontFamily={"AvenirBook"}>
            <u>CHECKED IN</u>
          </TSuiTypography>
        </TSuiBox>
        <TSuiBox pl={4} pr={4} mb={0} paddingBottom={0} minHeight={"35vh"}>
          {pageData.checkins.length === 0 && (
            <TSuiTypography variant="h2" fontWeight="bold" fontFamily={"AvenirBook"}>
              No checkins yet.
            </TSuiTypography>
          )}
          {pageData.checkins.map((checkin, index) => (
            <TSuiBox key={`${checkin.name}${checkin.room}`} display={"flex"} flexDirection={"row"} p={3}
              // sx={{width: "75%"}}
                     paddingTop={0}
                     justifyContent={"space-between"}>
              <TSuiTypography variant="h2" fontWeight="bold" fontFamily={"AvenirBook"}
                              className={"dotted-display"}
                              sx={{
                                textTransform: "uppercase",
                                display: "contents",
                                whiteSpace: "nowrap",
                                '&::after': {
                                  content: '"..........................................................................."',
                                  overflow: "hidden",
                                  letterSpacing: 4,
                                  paddingLeft: 1,
                                }
                              }}>
                {checkin.name}
              </TSuiTypography>
              <TSuiTypography variant="h2" fontWeight="bold" fontFamily={"AvenirBook"}
                              sx={{textTransform: "uppercase", display: "block", whiteSpace: "nowrap"}}>
                {checkin.room}
              </TSuiTypography>
            </TSuiBox>
          ))}
        </TSuiBox>

        <TSuiBox p={0} mb={0} textAlign="center" paddingBottom={0}>
          <TSuiTypography variant="h1" fontWeight={"bold"} fontFamily={"AvenirBook"}>
            <u>UPCOMING</u>
          </TSuiTypography>
        </TSuiBox>
        {pageData.upcomingAppts.map((upcoming, index) => (
          <TSuiBox key={`${upcoming}${index}`} display={"inline-block"} flexFlow={"row wrap"} p={3}
                   paddingTop={0}
                   width={"fit-content"}
                   justifyContent={"center"}>
            <TSuiTypography variant="h2" fontWeight="bold" fontFamily={"AvenirBook"}
                            sx={{textTransform: "uppercase", display: "inline-block", whiteSpace: "nowrap"}}>
              <>{upcoming.name} ({parseDate(upcoming.time * 1000).format("h:mm")})</>
            </TSuiTypography>
          </TSuiBox>
        ))}
        {/*</Card>*/}
      </TSuiBox>
    </APDisplayLayout>
  )
}
