import {SocketClient} from "ezsockz-client";


interface Props {
  uri: string;
}

export class SocketConnector extends SocketClient {
  constructor(props: Props) {
    super({
      uri: props.uri,
      token: "notunique",
      onConnectionStatus: (status) => {
        // console.log(status)
      }
    });
  }


}

export const socketConnector = new SocketConnector({uri: "/"});
