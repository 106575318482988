import {useState} from "react";

// react-router-dom components
import {Link, RouteComponentProps} from "@reach/router";

// @mui material components
import Card from "@mui/material/Card";

// Soft UI Dashboard PRO React components
// Authentication layout components
import BasicLayout from "../../layouts/comoponents/BasicLayout";

// Images
import curved9 from "../../assets/images/first-slide.jpg";
import {TSuiBox} from "../../components/TSuiBox";
import {TSuiTypography} from "../../components/TSuiTypography";
import {TSuiButton} from "../../components/TSuiButton";
import {TSuiInput} from "../../components/TSuiInput";
import {useGlobalState} from "../../context/GlobalStateProvider";
import {postData} from "../../api/api";
import {ClientEvent} from "clientevent";

interface Props extends RouteComponentProps {

}

export function Login(props: Props) {
  // const [, dispatch] = useSoftUIController();
  // const [rememberMe, setRememberMe] = useState(false);
  const [userVals, setUserVals] = useState<{ email: string, password: string }>({email: "", password: ""});
  const {setState} = useGlobalState();

  const userValHandler = (key: "email" | "password", val: string) => {
    setUserVals(prev => ({...prev, [key]: val}));
  };

  // const handleSetRememberMe = () => setRememberMe(!rememberMe);

  let onSubmit = () => {
    postData("login", userVals).then(async (result) => {
      if (result.status === 200) {
        setState({userInfo: {username: "", token: result.data.token}});
      } else {
        ClientEvent.publish("SNACKBAR", `Error: ${result.statusText}`);
        console.log(result.statusText)
      }
    }).catch((err) => {
      console.log(err)
      ClientEvent.publish("SNACKBAR", `Error: ${err.response.statusText}`);
    })
  };

  return (
    <BasicLayout
      title="Welcome!"
      description="Login using your mobile app credentials to manage your account online"
      image={curved9}
    >
      <Card>
        <TSuiBox p={3} mb={1} textAlign="center">
          <TSuiTypography variant="h5" fontWeight="medium">
            Sign in
          </TSuiTypography>
        </TSuiBox>
        <TSuiBox p={3}>
          <TSuiBox component="form" role="form" onSubmit={(evt) => {
            evt.preventDefault();
          }}>
            <TSuiBox mb={2}>
              <TSuiInput type="email"
                         placeholder="Email"
                         value={userVals.email}
                         onChange={(evt) => userValHandler("email", evt.target.value)}/>
            </TSuiBox>
            <TSuiBox mb={2}>
              <TSuiInput type="password"
                         placeholder="Password"
                         value={userVals.password}
                         onKeyUp={(evt) => {
                           if (evt.key === "Enter") {
                             evt.preventDefault();
                             evt.stopPropagation();
                             onSubmit();
                           }
                         }}
                         onChange={(evt) => userValHandler("password", evt.target.value)}/>
            </TSuiBox>
            {/*<TSuiBox display="flex" alignItems="center">*/}
            {/*  <Switch checked={rememberMe} onChange={handleSetRememberMe}/>*/}
            {/*  <TSuiTypography*/}
            {/*    variant="button"*/}
            {/*    fontWeight="regular"*/}
            {/*    onClick={handleSetRememberMe}*/}
            {/*    sx={{cursor: "pointer", userSelect: "none"}}*/}
            {/*  >*/}
            {/*    &nbsp;&nbsp;Remember me*/}
            {/*  </TSuiTypography>*/}
            {/*</TSuiBox>*/}
            <TSuiBox mt={4} mb={1}>
              <TSuiButton variant="gradient" color="info" fullWidth onClick={onSubmit}>
                sign in
              </TSuiButton>
            </TSuiBox>
            <TSuiTypography variant="button" color="text" fontWeight="regular">
              <>Don't have an account?&nbsp;</>
              <TSuiTypography
                component={Link}
                to="/register"
                variant="button"
                color="dark"
                fontWeight="bold"
                textGradient
              >
                Register
              </TSuiTypography>
            </TSuiTypography>
            <TSuiBox>
              <TSuiTypography variant="button" color="text" fontWeight="regular">
                <>Can't login?&nbsp;</>
                <TSuiTypography
                  component={Link}
                  to="/login/forgot"
                  variant="button"
                  color="dark"
                  fontWeight="bold"
                  textGradient
                >
                  Reset Password
                </TSuiTypography>
              </TSuiTypography>
            </TSuiBox>
          </TSuiBox>
        </TSuiBox>
      </Card>
    </BasicLayout>
  );
}
