import {useState} from "react";

// react-router-dom components
import {Link, RouteComponentProps} from "@reach/router";

// @mui material components
import Card from "@mui/material/Card";

// Soft UI Dashboard PRO React components
// Authentication layout components
import BasicLayout from "../../layouts/comoponents/BasicLayout";

// Images
import curved9 from "../../assets/images/first-slide.jpg";
import {TSuiBox} from "../../components/TSuiBox";
import {TSuiTypography} from "../../components/TSuiTypography";
import {TSuiButton} from "../../components/TSuiButton";
import {TSuiInput} from "../../components/TSuiInput";
import {useGlobalState} from "../../context/GlobalStateProvider";
import {postData} from "../../api/api";
import {ClientEvent} from "clientevent";
import {IPackage} from "../../interfaces/packages";
import * as regex from "../../constants/regex";

interface Props extends RouteComponentProps {

}

interface RegistrationForm {
  email: string;
};
let defaultReg: RegistrationForm = {
  email: "",
};

export function ForgotPassword(props: Props) {
  // const [, dispatch] = useSoftUIController();
  // const [rememberMe, setRememberMe] = useState(false);
  const [userVals, setUserVals] = useState<RegistrationForm>({...defaultReg});
  const {setState} = useGlobalState();
  const [showTerms, setShowTerms] = useState(false);
  const [viewedTerms, setViewedTerms] = useState(false);
  const [showSignupResult, setShowSignupResult] = useState(false);
  const [packageData, setPackageData] = useState<IPackage[]>([]);
  const [userId, setUserId] = useState<string>("");

  const userValHandler = (key: keyof RegistrationForm, val: any) => {
    setUserVals(prev => ({...prev, [key]: val}));
  };

  let onSubmit = () => {
    if (userVals.email === "") {
      return ClientEvent.publish("SNACKBAR", `Error: Email is required`);
    }
    if (!regex.email.test(userVals.email)) {
      return ClientEvent.publish("SNACKBAR", `Error: Email is invalid`);
    }
    postData("forgot", userVals).then(async (result) => {
      if (result.status === 200) {
        setShowSignupResult(true);
        // setState({userInfo: {username: "", token: result.data.token}});
      } else {
        ClientEvent.publish("SNACKBAR", `Error: ${result.statusText}`);
        console.log(result.statusText)
      }
    }).catch((err) => {
      console.log(err)
      ClientEvent.publish("SNACKBAR", `Error: ${err.response.statusText}`);
    })
  };

  return (
    <BasicLayout
      title="Welcome!"
      description="Forgot your password? No worries. Enter your email here and we'll send you a link to reset your password."
      image={curved9}
      gridOverrides={{lg: 8, xl: 6, md: 8, sm: 11, xs: 12}}
    >
      {!showSignupResult ?

        <Card>
          <TSuiBox p={3} mb={1} textAlign="center">
            <TSuiTypography variant="h5" fontWeight="medium">
              Forgot Password
            </TSuiTypography>
          </TSuiBox>
          <TSuiBox p={3}>
            <TSuiBox component="form" role="form" onSubmit={(evt) => {
              evt.preventDefault();
              onSubmit();
            }}>

              <TSuiBox mb={2}>
                <TSuiInput type="email"
                           placeholder="Email"
                           value={userVals.email}
                           error={!regex.email.test(userVals.email)}
                           success={regex.email.test(userVals.email)}
                           onChange={(evt) => userValHandler("email", evt.target.value)}/>
              </TSuiBox>
              <TSuiBox mt={4} mb={1}>
                <TSuiButton variant="gradient" color="info" fullWidth onClick={onSubmit}>
                  Request Password Reset
                </TSuiButton>
              </TSuiBox>
              <TSuiTypography variant="button" color="text" fontWeight="regular">
                <>Remember your credentials?&nbsp;</>
                <TSuiTypography
                  component={Link}
                  to="/login"
                  variant="button"
                  color="dark"
                  fontWeight="bold"
                  textGradient
                >
                  Sign in
                </TSuiTypography>
              </TSuiTypography>
            </TSuiBox>
          </TSuiBox>
        </Card>
        :
        <Card>
          <TSuiBox p={3} mb={1} textAlign="center">
            <TSuiTypography variant="h5" fontWeight="medium">
              Forgot Password
            </TSuiTypography>
          </TSuiBox>
          <TSuiBox p={3}>
            <TSuiTypography variant="button" color="text" fontWeight="regular">
              <TSuiBox p={3}>
                If we find an account with that email, we'll send you a link to reset your password. The validation url
                inside the email will be valid for 20 minutes.
                (If you don't see our validation email, check your Junk Mail folder)
              </TSuiBox>


              <>Suddenly remember your credentials? &nbsp;</>
              <TSuiTypography
                component={Link}
                to="/login"
                variant="button"
                color="dark"
                fontWeight="bold"
                textGradient
              >
                Sign in
              </TSuiTypography>
              <>&nbsp; now</>
            </TSuiTypography>
          </TSuiBox>
        </Card>
      }

    </BasicLayout>
  )
    ;
}
