import {forwardRef} from "react";
import TSuiBadgeRoot from "./TSuiBadgeRoot";
import {TSuiBadgeProps} from "./types";

// Custom styles for the SuiBadge
// import TSuiBadgeRoot from "./TSuiBadgeRoot";

const TSuiBadge = forwardRef<any, TSuiBadgeProps>(({
                                                     color = "info",
                                                     variant = "gradient",
                                                     size = "sm",
                                                     circular = false,
                                                     indicator = false,
                                                     border = false,
                                                     container = false,
                                                     children,
                                                     ...rest
                                                   }, ref) => (
  // @ts-ignore
  <TSuiBadgeRoot {...rest} ownerState={{color, variant, size, circular, indicator, border, container, children} as any}
                 ref={ref} color="default">
    {children}
  </TSuiBadgeRoot>
));


// Setting default values for the props of SuiBadge
// SuiBadge.defaultProps = {
//   color: "info",
//   variant: "gradient",
//   size: "sm",
//   circular: false,
//   indicator: false,
//   border: false,
//   children: false,
//   container: false,
// };

// Typechecking props of the SuiBadge
// SuiBadge.propTypes = {
//   color: PropTypes.oneOf([
//     "primary",
//     "secondary",
//     "info",
//     "success",
//     "warning",
//     "error",
//     "light",
//     "dark",
//   ]),
//   variant: PropTypes.oneOf(["gradient", "contained"]),
//   size: PropTypes.oneOf(["xs", "sm", "md", "lg"]),
//   circular: PropTypes.bool,
//   indicator: PropTypes.bool,
//   border: PropTypes.bool,
//   children: PropTypes.node,
//   container: PropTypes.bool,
// };


export default TSuiBadge;
