/**
 =========================================================
 * Soft UI Dashboard PRO React - v3.0.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
 * Copyright 2021 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

import {useEffect, useMemo, useState} from "react";

// react-router components
import {useLocation} from "@reach/router";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @material-ui core components
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import Icon from "@mui/material/Icon";

// Soft UI Dashboard PRO React components
import {TSuiBox} from "../../../components/TSuiBox";

// Soft UI Dashboard PRO React example components
// import NotificationItem from "examples/Items/NotificationItem";
// Custom styles for DashboardNavbar
import {navbar, navbarContainer, navbarDesktopMenu, navbarIconButton, navbarRow, navbarSmallMenu} from "./styles";

// Soft UI Dashboard PRO React context
import {setMiniSidenav, setOpenConfigurator, setTransparentNavbar, useSoftUIController} from "../../../context";

// Images
import TSuiBadge from "../../../components/TSuiBadge";
import {useGlobalState} from "../../../context/GlobalStateProvider";
import {plural} from "../../../shared/util";

function DashboardNavbar({absolute, light, isMini}) {
  const [navbarType, setNavbarType] = useState();
  const [controller, dispatch] = useSoftUIController();
  const {miniSidenav, transparentNavbar, fixedNavbar, openConfigurator} = controller;
  const [openMenu, setOpenMenu] = useState(false);
  const route = useLocation().pathname.split("/").slice(1);
  const {state: {balance}} = useGlobalState();

  useEffect(() => {
    // Setting the navbar type
    if (fixedNavbar) {
      setNavbarType("sticky");
    } else {
      setNavbarType("static");
    }

    // A function that sets the transparent state of the navbar.
    function handleTransparentNavbar() {
      setTransparentNavbar(dispatch, (fixedNavbar && window.scrollY === 0) || !fixedNavbar);
    }

    /**
     The event listener that's calling the handleTransparentNavbar function when
     scrolling the window.
     */
    window.addEventListener("scroll", handleTransparentNavbar);

    // Call the handleTransparentNavbar function to set the state with the initial value.
    handleTransparentNavbar();

    // Remove event listener on cleanup
    return () => window.removeEventListener("scroll", handleTransparentNavbar);
  }, [dispatch, fixedNavbar]);

  const credits = useMemo(() => {
    return balance === undefined ? -1 : balance;
  }, [balance]);

  const handleMiniSidenav = () => setMiniSidenav(dispatch, !miniSidenav);
  const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);
  const handleOpenMenu = (event) => setOpenMenu(event.currentTarget);
  const handleCloseMenu = () => setOpenMenu(false);

  // Render the notifications menu
  const renderMenu = () => (
    <Menu
      anchorEl={openMenu}
      anchorReference={null}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      open={Boolean(openMenu)}
      onClose={handleCloseMenu}
      sx={{mt: 2}}
    >
      {/*<NotificationItem*/}
      {/*  image={<img src={team2} alt="person" />}*/}
      {/*  title={["New message", "from Laur"]}*/}
      {/*  date="13 minutes ago"*/}
      {/*  onClick={handleCloseMenu}*/}
      {/*/>*/}
      {/*<NotificationItem*/}
      {/*  image={<img src={logoSpotify} alt="person" />}*/}
      {/*  title={["New album", "by Travis Scott"]}*/}
      {/*  date="1 day"*/}
      {/*  onClick={handleCloseMenu}*/}
      {/*/>*/}
      {/*<NotificationItem*/}
      {/*  color="secondary"*/}
      {/*  image={*/}
      {/*    <Icon fontSize="small" sx={{ color: ({ palette: { white } }) => white.main }}>*/}
      {/*      payment*/}
      {/*    </Icon>*/}
      {/*  }*/}
      {/*  title={["", "Payment successfully completed"]}*/}
      {/*  date="2 days"*/}
      {/*  onClick={handleCloseMenu}*/}
      {/*/>*/}
    </Menu>
  );

  return (
    <AppBar
      position={absolute ? "absolute" : navbarType}
      color="inherit"
      sx={(theme) => navbar(theme, {transparentNavbar, absolute, light})}
    >
      <Toolbar sx={(theme) => navbarContainer(theme)}>
        <TSuiBox color="inherit" mb={{xs: 1, md: 1}} sx={(theme) => navbarRow(theme, {isMini})}>
          {/*<Breadcrumbs icon="home" title={route[route.length - 1]} route={route} light={light} />*/}
          <Icon fontSize="medium" sx={navbarDesktopMenu} onClick={handleMiniSidenav}>
            {miniSidenav ? "menu_open" : "menu"}
          </Icon>
          <Icon fontSize="medium" sx={navbarSmallMenu} onClick={handleMiniSidenav}>
            menu
          </Icon>
        </TSuiBox>
        {isMini ? null : (
          <TSuiBox sx={(theme) => navbarRow(theme, {isMini})}>
            {/*<TSuiBox pr={1}>*/}
            {/*  <TSuiInput*/}
            {/*    placeholder="Type here..."*/}
            {/*    icon={{ component: "search", direction: "left" }}*/}
            {/*  />*/}
            {/*</TSuiBox>*/}
            <TSuiBox color={light ? "white" : "inherit"}>
              {/*  <Link to="/authentication/sign-in/basic">*/}
              {/*    <IconButton sx={navbarIconButton} size="small">*/}
              {/*      <Icon*/}
              {/*        sx={({ palette: { dark, white } }) => ({*/}
              {/*          color: light ? white.main : dark.main,*/}
              {/*        })}*/}
              {/*      >*/}
              {/*        account_circle*/}
              {/*      </Icon>*/}
              {/*      <TSuiTypography*/}
              {/*        variant="button"*/}
              {/*        fontWeight="medium"*/}
              {/*        color={light ? "white" : "dark"}*/}
              {/*      >*/}
              {/*        Sign in*/}
              {/*      </TSuiTypography>*/}
              {/*    </IconButton>*/}
              {/*  </Link>*/}
              {/*  <IconButton*/}
              {/*    size="small"*/}
              {/*    color="inherit"*/}
              {/*    sx={navbarMobileMenu}*/}
              {/*    onClick={handleMiniSidenav}*/}
              {/*  >*/}
              {/*    <Icon className={light ? "text-white" : "text-dark"}>*/}
              {/*      {miniSidenav ? "menu_open" : "menu"}*/}
              {/*    </Icon>*/}
              {/*  </IconButton>*/}
              {/*  <IconButton*/}
              {/*    size="small"*/}
              {/*    color="inherit"*/}
              {/*    sx={navbarIconButton}*/}
              {/*    onClick={handleConfiguratorOpen}*/}
              {/*  >*/}
              {/*    <Icon>settings</Icon>*/}
              {/*  </IconButton>*/}
              {credits > -1 && (
                <IconButton
                  size="small"
                  color="inherit"
                  sx={navbarIconButton}
                  aria-controls="notification-menu"
                  aria-haspopup="true"
                  variant="contained"
                  onClick={handleOpenMenu}
                  title={"Credits"}
                >
                  <TSuiBadge badgeContent={`${credits} ${plural(credits, "Credit", "Credits")}`} size={"xs"}
                             circular={false}
                             container={true}>
                    {/*<Icon className={light ? "text-white" : "text-dark"}>notifications</Icon>*/}
                  </TSuiBadge>
                </IconButton>
              )}
              <IconButton
                size="small"
                color="inherit"
                sx={navbarIconButton}
                aria-controls="notification-menu"
                aria-haspopup="true"
                variant="contained"
                onClick={handleOpenMenu}
              >
                <Icon className={light ? "text-white" : "text-dark"}>notifications</Icon>
              </IconButton>
              {renderMenu()}
            </TSuiBox>
          </TSuiBox>
        )}
      </Toolbar>
    </AppBar>
  );
}

// Setting default values for the props of DashboardNavbar
DashboardNavbar.defaultProps = {
  absolute: false,
  light: false,
  isMini: false,
};

// Typechecking props for the DashboardNavbar
DashboardNavbar.propTypes = {
  absolute: PropTypes.bool,
  light: PropTypes.bool,
  isMini: PropTypes.bool,
};

export default DashboardNavbar;
