import {useEffect, useState} from "react";

// react-router-dom components
import {Link, RouteComponentProps, useNavigate} from "@reach/router";

// @mui material components
import Card from "@mui/material/Card";

// Soft UI Dashboard PRO React components
// Authentication layout components
import BasicLayout from "../../layouts/comoponents/BasicLayout";

// Images
import curved9 from "../../assets/images/first-slide.jpg";
import {TSuiBox} from "../../components/TSuiBox";
import {TSuiTypography} from "../../components/TSuiTypography";
import {TSuiButton} from "../../components/TSuiButton";
import {TSuiInput} from "../../components/TSuiInput";
import {useGlobalState} from "../../context/GlobalStateProvider";
import {postData} from "../../api/api";
import {ClientEvent} from "clientevent";

interface Props extends RouteComponentProps {
  code?: string;
}

interface RegistrationForm {
  code: string;
}

let defaultReg: RegistrationForm = {
  code: ""
};

export function RegistrationValidation(props: Props) {
  // const [, dispatch] = useSoftUIController();
  // const [rememberMe, setRememberMe] = useState(false);
  const navigate = useNavigate();
  const [userVals, setUserVals] = useState<RegistrationForm>({...defaultReg, code: props.code || ""});
  const {setState} = useGlobalState();

  const userValHandler = (key: keyof RegistrationForm, val: any) => {
    setUserVals(prev => ({...prev, [key]: val}));
  };

  // const handleSetRememberMe = () => setRememberMe(!rememberMe);

  let onSubmit = () => {
    if (userVals.code === "") {
      return ClientEvent.publish("SNACKBAR", `Error: Code is Required`);
    }
    postData("validate", userVals).then(async (result) => {
      if (result.status === 200) {
        setState({userInfo: {username: "", token: result.data.token}});
        navigate(`/`);
      } else {
        ClientEvent.publish("SNACKBAR", `Error: ${result.statusText}`);
        console.log(result.statusText)
      }
    }).catch((err) => {
      console.log(err)
      ClientEvent.publish("SNACKBAR", `Error: ${err.response.statusText}`);
    })
  };

  useEffect(()=>{
    if (props.code){
      onSubmit();
    }
  }, [props.code]);

  return (
    <BasicLayout
      title="Welcome!"
      description="Register for an account today!"
      image={curved9}
    >
      <Card>
        <TSuiBox p={3} mb={1} textAlign="center">
          <TSuiTypography variant="h5" fontWeight="medium">
            Registration Validation
          </TSuiTypography>
        </TSuiBox>
        <TSuiBox p={3}>
          <TSuiBox component="form" role="form">
            <TSuiBox mb={2}>
              <TSuiInput type="code"
                         placeholder="Code"
                         value={userVals.code}
                         onChange={(evt) => userValHandler("code", evt.target.value)}/>
            </TSuiBox>
            {/*<TSuiBox display="flex" alignItems="center">*/}
            {/*  <Switch checked={true} onChange={() => undefined}/>*/}
            {/*  <TSuiTypography*/}
            {/*    variant="button"*/}
            {/*    fontWeight="regular"*/}
            {/*    onClick={() => undefined}*/}
            {/*    sx={{cursor: "pointer", userSelect: "none"}}*/}
            {/*  >*/}
            {/*    &nbsp;&nbsp;Remember me*/}
            {/*  </TSuiTypography>*/}
            {/*</TSuiBox>*/}
            <TSuiBox mt={4} mb={1}>
              <TSuiButton variant="gradient" color="info" fullWidth onClick={onSubmit}>
                Validate
              </TSuiButton>
            </TSuiBox>
            <TSuiTypography variant="button" color="text" fontWeight="regular">
              <>Already have an account?&nbsp;</>
              <TSuiTypography
                component={Link}
                to="/login"
                variant="button"
                color="dark"
                fontWeight="bold"
                textGradient
              >
                Sign in
              </TSuiTypography>
            </TSuiTypography>
          </TSuiBox>
        </TSuiBox>
      </Card>
    </BasicLayout>
  );
}
